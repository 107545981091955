import { NavBar, PullRefresh, List, Image, Icon } from 'vant'
// import {videoPlayer} from 'vue-video-player'
export default {
  name:'liveDetail',
  components:{
    'van-icon':Icon,
    'van-nav-bar':NavBar,
    'van-pull-refresh':PullRefresh,
    'van-list':List,
    'van-image':Image,
    // 'video-player':videoPlayer,
  },
  data () {

    return {

      apis:{//接口地址
        video:'/video/findVideo',//获取视频详情
        tickets: '/commodity/queryCommodity',//商家商品列表
        ways:'/group/queryGroupByGroupTypeId',//路线列表
      },
      videoKey:1,//视频主键
      video:{},//视频对象
      playing:false,//是否正在播放语音
      tickets:[],//门票列表
      ways:[],//路线列表
      playerOptions : {
        playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
        autoplay : false, //如果true,浏览器准备好时开始回放。
        muted : false, // 默认情况下将会消除任何音频。
        loop : false, // 视频一结束就重新开始。
        preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language : 'zh-CN',
        aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources : [ {
          type : "",
          src : null//url地址
        } ],
        poster : "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar : {
          timeDivider : true,//当前时间和持续时间的分隔符
          durationDisplay : true,//显示持续时间
          remainingTimeDisplay : false,//是否显示剩余时间功能
          fullscreenToggle : true  //全屏按钮
        }
      },

    }
  },
  mounted() {
    const me = this;
    me.videoKey = me.$route.params.id;
    me.loadVideo();
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  methods: {
    // 查看详情
    detail(v) {
      this.$router.push(`/lineTwoDetails/?from=list&groupId=${v}`)
      sessionStorage.setItem('key',1)
    },
    onCancel() {
      this.$router.go(-1)

    },
    loadVideo(){//获取视频对象
      const me = this;
      me.types = [];
      me.$get({
        url:me.apis.video,
        params:{
          videoId: me.videoKey
        }
      }).then(rsp => {
        me.video = rsp ? rsp : {};
        console.log(me.video);
        me.loadTickets();
        me.loadWays();
        var files = me.video.attachments;
        if(files && files.length > 0){
            for(var i = 0, n = files.length; i < n; i++){
              if(files[i].fileType != 2){
                continue;
              }
              me.playerOptions.sources[0].src = files[i].url;
              me.playerOptions.poster = files[i].coverAttachment.url;

              console.log(me.playerOptions);
            }
        }
      });
    },
    play(){//播放或暂停播放
      const me = this;
      me.audio = new Audio(me.video.audiobook);
      if(me.audio && me.playing){
          me.playing ? me.audio.pause() : me.audio.play();
          me.playing = !me.playing;
      }
    },
    loadTickets(){//获取相关门票列表
      const me = this;
      me.tickets = [];
      if(!me.video || !me.video.name){
        return;
      }
      me.$get({
          url:me.apis.tickets,
          params: {
            tags: me.video.name,//标签
            pageIndex:1
          }
      }).then(rsp => {
        me.tickets = rsp.data;
      });
    },
    loadWays(){//获取相关路线列表
      const me = this;
      me.ways = [];
      if(!me.video || !me.video.name){
        return;
      }
      me.$get({
          url:me.apis.ways,
          params: {
            tags: me.video.name,//标签
            pageIndex:1
          }
      }).then(rsp => {
        me.ways = rsp.data;
      });
    }
  },

}
